import { FormControlLabel, Typography, Checkbox, Link } from '@material-ui/core';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppState } from 'store/app/hooks';
import useStyles from './Terms.styles';

const Terms: FC = () => {
  const classes = useStyles();
  const { showPolicy, showTerms, agreeTerms, termsAgreed } = useAppState();
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={termsAgreed}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            agreeTerms(event.target.checked);
          }}
          name="termsAndConditions"
          color="primary"
          size="small"
          id="termsAndConditions"
        />
      }
      label={
        <Typography className={classes.termsText}>
          {t('pages.home.agreeToTerms.line1')} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link href="#" className={classes.termsLink} onClick={() => showPolicy(true)} id="privacyLink">
            {t('pages.home.agreeToTerms.line2')}{' '}
          </Link>
          {t('pages.home.agreeToTerms.line3')} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link href="#" className={classes.termsLink} onClick={() => showTerms(true)} id="termsLink">
            {t('pages.home.agreeToTerms.line4')}
          </Link>
          .
        </Typography>
      }
      className={classes.termsContainer}
    />
  );
};

export default Terms;

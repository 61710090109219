import { Box, Button, Container, Drawer, Fab, Hidden, IconButton, Typography } from '@material-ui/core';
import { ArrowBack, ArrowForward, Close, HelpOutline as Help } from '@material-ui/icons';
import backgroundSvg from 'assets/icons/bsCoreBackground.svg';
import backgroundShapes2Svg from 'assets/icons/bScoreShapes2Background.svg';
import backgroundShapesSvg from 'assets/icons/bsCoreShapesBackground.svg';
import clsx from 'clsx';
import { FaqsModal } from 'components/FaqsModal';
import { businessScore } from 'core/constants';
import { MessageAlertStatus } from 'core/types';
import { FC, ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppState } from 'store/app/hooks';
import { useAuth } from 'store/auth/hooks';
import { useGlobalState } from 'store/global/hooks';
import { useOffers } from 'store/offers/hooks';
import { useOnboard } from 'store/onboard/hooks';
import { noop } from 'utils';
import { MessageAlert } from '../alerts';
import { LoadingButton } from '../buttons';
import { Navbar } from '../Navbar';
import { Sidebar } from '../Sidebar';
import { AppsMenu } from './components';
import useStyles from './Layout.styles';

interface LayoutProps {
  mainButtonTitle?: string;
  onMainAction?: () => void;
  headerTitle?: string;
  mainButtonDisabled?: boolean;
  withBackButton?: boolean;
  secondaryButtonTitle?: string;
  onSecondaryAction?: () => void;
  onGoBack?: () => void;
  noError?: boolean;
  ActionLink?: ReactNode;
  mainButtonLoading?: boolean;
  error?: boolean | string;
  clearError?: () => void;
  showSidebar?: boolean;
  showBackground?: boolean;
  loading?: boolean;
  Loader?: ReactNode;
  showShapeBackground?: boolean;
  withCloseButton?: boolean;
  onCloseApp?: () => void;
  showShape2Background?: boolean;
  MainActionMessage?: ReactNode;
  SecondaryActionMessage?: ReactNode;
  onGoForward?: () => void;
}

const Layout: FC<LayoutProps> = ({
  children,
  mainButtonTitle,
  headerTitle,
  onMainAction,
  secondaryButtonTitle,
  onSecondaryAction,
  onGoBack,
  mainButtonDisabled = false,
  withBackButton = true,
  noError = false,
  ActionLink,
  mainButtonLoading = false,
  error = false,
  clearError,
  showSidebar = true,
  showBackground = false,
  loading = false,
  Loader,
  showShapeBackground = false,
  withCloseButton = false,
  onCloseApp = noop,
  showShape2Background = false,
  MainActionMessage,
  SecondaryActionMessage,
  onGoForward,
}) => {
  const [faqsOpen, setFaqsOpen] = useState(false);
  const classes = useStyles();
  const onboardState = useOnboard();
  const offersState = useOffers();
  const { t } = useTranslation();
  const { widget, height, width, faqEnabled, showPolicy, showTerms, termsVisible, policyVisible } = useAppState();
  const { goBack } = useHistory();
  const { error: globalError } = useGlobalState();
  const { adminEditMode } = useAuth();

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    } else {
      goBack();
    }
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    if (onboardState.error) onboardState.clearError();
    if (offersState.error) offersState.clearError();
    if (clearError) clearError();
  };

  const handleSuccessClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    if (onboardState.success) onboardState.setSuccess(false);
  };

  const toggleFaqs = () => {
    setFaqsOpen((prevState) => !prevState);
  };

  const showDesktop = useMemo(() => {
    if (widget && width) {
      if (width === '100vw') {
        return true;
      }
      const widthNumber = typeof width === 'number' ? width : Number(width.replace('px', ''));
      if (widthNumber >= 1280) {
        return true;
      }
    }
    return false;
  }, [widget, width]);

  const containerClasses = useMemo(() => {
    const classNames = [classes.container];
    if (widget) {
      if (!showDesktop) {
        classNames.push(classes.widgetContainer);
      } else if (showSidebar) {
        classNames.push(classes.desktopContainer);
      }
    } else if (showSidebar) {
      classNames.push(classes.desktopContainer);
    }

    return clsx(classNames);
  }, [classes.container, classes.widgetContainer, classes.desktopContainer, showDesktop, showSidebar, widget]);

  const backButtonClassName = useMemo(() => {
    if (widget && !showDesktop) {
      return clsx([classes.backButton, classes.widgetBackButton]);
    }
    return classes.backButton;
  }, [classes.backButton, classes.widgetBackButton, showDesktop, widget]);

  const topRightContainerClasses = useMemo(() => {
    if (widget && !showDesktop) {
      return clsx([classes.topRightContainer, classes.widgetTopRightContainer]);
    }
    return classes.topRightContainer;
  }, [classes.topRightContainer, classes.widgetTopRightContainer, showDesktop, widget]);

  const showNavbarAndSideBar = (widget && showDesktop) || !widget;

  const currentError = onboardState.error || offersState.error || error;

  const getMainActionTitle = () => {
    if (adminEditMode) return t('global.actions.update');
    if (mainButtonTitle) return mainButtonTitle;
    return t('global.actions.next');
  };

  return (
    <Box width={width} height={height} className={classes.topContainer}>
      {showBackground && (
        <Box className={classes.background}>
          <img src={backgroundSvg} alt="Background" />
        </Box>
      )}

      {showShapeBackground && (
        <Box className={classes.background}>
          <img src={backgroundShapesSvg} alt="Background" />
        </Box>
      )}

      {showShape2Background && (
        <Box className={classes.background}>
          <img src={backgroundShapes2Svg} alt="Background" />
        </Box>
      )}

      <Box width="100%" height="100%" overflow="scroll">
        {showNavbarAndSideBar && (
          <Hidden mdDown>
            <Navbar />
          </Hidden>
        )}

        <Container className={containerClasses} maxWidth="sm">
          {showNavbarAndSideBar && <Hidden mdDown>{showSidebar && <Sidebar />}</Hidden>}

          {withBackButton && (
            <IconButton className={backButtonClassName} onClick={handleGoBack}>
              <ArrowBack className={classes.arrowBackIcon} id="goBackButton" />
            </IconButton>
          )}

          <Box className={topRightContainerClasses}>
            <AppsMenu />

            {!withCloseButton && onGoForward && (
              <IconButton onClick={onGoForward}>
                <ArrowForward className={classes.arrowBackIcon} id="goForwardButton" />
              </IconButton>
            )}

            {withCloseButton && (
              <IconButton onClick={onCloseApp} id="closeAppButton">
                <Close />
              </IconButton>
            )}
          </Box>

          {headerTitle && (
            <header className={classes.header}>
              <Typography className={classes.title}>{headerTitle}</Typography>
            </header>
          )}

          <Box className={classes.main}>{children}</Box>

          {onSecondaryAction && (
            <Box className={classes.buttonContainer}>
              {SecondaryActionMessage && SecondaryActionMessage}
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                className={classes.button}
                fullWidth
                onClick={onSecondaryAction}
                id="secondaryButton"
              >
                {secondaryButtonTitle || t('global.actions.next')}
              </Button>
            </Box>
          )}

          {onMainAction && (
            <Box className={classes.buttonContainer} style={{ paddingTop: onSecondaryAction ? 0 : 40 }}>
              {MainActionMessage && MainActionMessage}
              <LoadingButton
                variant="contained"
                color="primary"
                className={classes.button}
                fullWidth
                onClick={onMainAction}
                disabled={mainButtonDisabled}
                loading={onboardState.loading || mainButtonLoading}
                id="mainButton"
              >
                {getMainActionTitle()}
              </LoadingButton>
            </Box>
          )}

          {ActionLink}

          {faqEnabled && (
            <>
              <Fab className={classes.faqButton} onClick={toggleFaqs} variant="extended">
                <Help className={classes.faqIcon} id="faqButton" />
                FAQs
              </Fab>

              <FaqsModal open={faqsOpen} toggleOpen={toggleFaqs} />
            </>
          )}

          {adminEditMode && (
            <MessageAlert
              open={!!onboardState.success}
              handleClose={handleSuccessClose}
              message={typeof onboardState.success === 'string' ? onboardState.success : undefined}
              status={MessageAlertStatus.SUCCESS}
            />
          )}

          {!noError && !globalError && (
            <MessageAlert
              open={!!currentError}
              handleClose={handleClose}
              message={typeof currentError === 'string' ? currentError : undefined}
            />
          )}
        </Container>

        {loading && <>{Loader}</>}
      </Box>
      <Drawer
        variant="persistent"
        anchor="left"
        open={termsVisible || policyVisible}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={() => {
              showTerms(false);
              showPolicy(false);
            }}
            id="closeDrawerButton"
          >
            <Close />
          </IconButton>
        </div>
        <iframe
          title="termlyPolicy"
          id="termlyPolicy"
          name="termly-embed"
          data-id="db085ece-8fd1-4604-8caf-8014da3b7113"
          data-type="iframe"
          src={businessScore.websitePolicy}
          style={{
            visibility: policyVisible ? 'visible' : 'hidden',
            position: 'absolute',
            top: 20,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: policyVisible ? 10 : -10,
            width: '100%',
            height: '100%',
          }}
        />
        <iframe
          title="termlyTerms"
          id="termlyTerms"
          name="termly-embed"
          data-id="1539755a-5099-4d33-9efd-4047d92e9683"
          data-type="iframe"
          src={businessScore.websiteTerms}
          style={{
            visibility: termsVisible ? 'visible' : 'hidden',
            position: 'absolute',
            top: 20,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: termsVisible ? 10 : -10,
            width: '100%',
            height: '100%',
          }}
        />
      </Drawer>
    </Box>
  );
};

export default Layout;

import { FC, ChangeEvent, useState, useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useCommonStyles from 'core/styles';
import { CustomSelect, CustomTextField, CustomDatePicker, CustomLabel } from 'components/inputs';
import routes from 'router/routes';
import { businessTypeOptions } from 'core/constants';
import { Layout } from 'components/Layout';
import { useOnboard } from 'store/onboard/hooks';
import { Company } from 'store/onboard/types';
import analytics from 'core/services/analytics';
import { getDateFromDateOnly } from 'utils';
import { HideOptionalFields } from 'components/HideOptionalFields';
import { useAuth } from 'store/auth/hooks';
import { Loader } from 'components/Loader';
import useStyles from './CompanyInfo.styles';
import { formSchema, FormValues, getInitialValues, httpifyURL } from './utils';

const CompanyInfo: FC = () => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { company, hideOptionalFields, patchCompany, setSuccess, loading, prequalifyResult } = useOnboard();
  const { t } = useTranslation();
  const [diffDates, setDiffDates] = useState(
    company.trading_from_date
      ? moment(company.formation_date).diff(moment(company.trading_from_date), 'days') !== 0
      : false,
  );
  const [isTradingNameDiff, setIsTradingNameDiff] = useState(!!company.trading_name?.trim());
  const { adminEditMode } = useAuth();
  const history = useHistory();

  useEffect(() => {
    analytics.track(routes.companyInfo);

    return () => setSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = getInitialValues(company);

  const onSubmit = ({ id, sic_codes, ...values }: typeof initialValues) => {
    if (!id) return;

    const companyData: Partial<Company> = {
      ...values,
      website: values.website ? httpifyURL(values.website) : null,
      vat_registration_date: values.vat_registration_date ? values.vat_registration_date.toISOString() : null,
      trading_from_date: values.trading_from_date ? values.trading_from_date.toISOString() : null,
    };
    companyData.sic_codes = sic_codes.split(', ').filter(Boolean);

    if (!diffDates && values.formation_date) {
      companyData.trading_from_date = getDateFromDateOnly(values.formation_date).toISOString();
    }

    patchCompany({ id, data: companyData });
  };

  const onGoForward = () => {
    history.push(routes.applicantInfo);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ handleChange, values, setFieldValue, handleSubmit, errors, submitCount }) => {
        return (
          <Layout
            headerTitle={t('pages.companyInfo.header')}
            onMainAction={handleSubmit}
            withBackButton={false}
            onGoForward={adminEditMode ? onGoForward : undefined}
            loading={loading && !prequalifyResult}
            Loader={<Loader visible timeout={0} />}
          >
            <Typography variant="h1" className={commonClasses.title2}>
              {t('pages.companyInfo.title')}
            </Typography>

            <Typography className={commonClasses.subtitle2}>{t('pages.companyInfo.description')}</Typography>

            <HideOptionalFields />

            <Form noValidate className={commonClasses.form}>
              <Grid container direction="column" spacing={4}>
                {!values.registered_name && (
                  <Grid item>
                    <Field
                      id="registered_name"
                      fullWidth
                      component={CustomTextField}
                      name="registered_name"
                      value={values.registered_name || ''}
                      onChange={handleChange}
                      title={t('pages.companyInfo.inputs.registered_name.label')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('pages.companyInfo.inputs.registered_name.placeholder')}
                      className={commonClasses.textInput}
                      disabled
                    />
                  </Grid>
                )}

                {!hideOptionalFields && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isTradingNameDiff}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const checked = !!event.target.checked;
                            setIsTradingNameDiff(checked);
                            if (!checked) {
                              setFieldValue('trading_name', null);
                            }
                          }}
                          name="trading_name_diff"
                          color="primary"
                          size="small"
                          id="tradingNameIsDiff"
                        />
                      }
                      label={
                        <CustomLabel
                          title={t('pages.companyInfo.inputs.trading_name.label', {
                            companyName: values.registered_name,
                          })}
                          optional
                        />
                      }
                      className={classes.inputLabel}
                    />
                  </Grid>
                )}

                {!hideOptionalFields && isTradingNameDiff && (
                  <Grid item>
                    <Field
                      id="trading_name"
                      fullWidth
                      component={CustomTextField}
                      name="trading_name"
                      value={values.trading_name || ''}
                      onChange={handleChange}
                      title={t('pages.companyInfo.inputs.trading_name.input')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('pages.companyInfo.inputs.trading_name.placeholder')}
                      className={commonClasses.textInput}
                      optional
                    />
                  </Grid>
                )}

                {!values.registered_number && (
                  <Grid item>
                    <Field
                      id="registered_number"
                      fullWidth
                      component={CustomTextField}
                      name="registered_number"
                      value={values.registered_number || ''}
                      onChange={handleChange}
                      title={t('pages.companyInfo.inputs.registered_number.label')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('pages.companyInfo.inputs.registered_number.placeholder')}
                      className={commonClasses.textInput}
                      disabled
                      hidden
                    />
                  </Grid>
                )}

                <Grid item>
                  <Field
                    id="type"
                    fullWidth
                    component={CustomSelect}
                    options={businessTypeOptions}
                    name="type"
                    value={values.type}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('type', event.target.value)}
                    title={t('pages.companyInfo.inputs.type.label')}
                    className={commonClasses.textInput}
                  />
                </Grid>

                {!hideOptionalFields && (
                  <Grid item>
                    <Field
                      id="website"
                      fullWidth
                      component={CustomTextField}
                      name="website"
                      value={values.website || ''}
                      onChange={handleChange}
                      title={t('pages.companyInfo.inputs.website.label')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('pages.companyInfo.inputs.website.placeholder')}
                      className={commonClasses.textInput}
                      optional
                    />
                  </Grid>
                )}

                <Grid item>
                  <Field
                    id="formation_date"
                    fullWidth
                    component={CustomTextField}
                    name="formation_date"
                    value={moment(values.formation_date).format('dd/mm/yyyy') || ''}
                    onChange={handleChange}
                    title={t('pages.companyInfo.inputs.formation_date.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.companyInfo.inputs.formation_date.placeholder')}
                    className={commonClasses.textInput}
                    disabled
                    helperText={moment(values.formation_date).format('Do MMMM YYYY')}
                  />
                </Grid>

                {!hideOptionalFields && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={diffDates}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const checked = !!event.target.checked;
                            setDiffDates(checked);
                            setFieldValue('trading_from_date', checked ? null : moment(values.formation_date).toDate());
                          }}
                          name="diffDates"
                          color="primary"
                          size="small"
                          id="diffDates"
                        />
                      }
                      label={<CustomLabel title={t('pages.companyInfo.inputs.sameDates.label')} optional />}
                      className={classes.inputLabel}
                    />
                  </Grid>
                )}

                {!hideOptionalFields && diffDates && (
                  <Grid item>
                    <CustomDatePicker
                      id="trading_from_date"
                      name="trading_from_date"
                      label={t('pages.companyInfo.inputs.trading_from_date.label')}
                      value={values.trading_from_date}
                      onChange={(date: Moment | null) => {
                        setFieldValue('trading_from_date', date?.toDate() ?? null);
                      }}
                      clearable
                      error={submitCount > 0 ? errors.trading_from_date : undefined}
                    />
                  </Grid>
                )}

                {!hideOptionalFields && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.vat_registered}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const vatRegistered = !!event.target.checked;
                            setFieldValue('vat_registered', vatRegistered);
                            if (!vatRegistered) {
                              setFieldValue('vat_number', null);
                              setFieldValue('vat_registration_date', null);
                            }
                          }}
                          color="primary"
                          size="small"
                          id="vat_registered"
                          name="vat_registered"
                        />
                      }
                      label={<CustomLabel title={t('pages.companyInfo.inputs.vat_registered.label')} optional />}
                      className={classes.inputLabel}
                    />
                  </Grid>
                )}

                {!hideOptionalFields && values.vat_registered && (
                  <>
                    <Grid item>
                      <Field
                        id="vat_number"
                        fullWidth
                        component={CustomTextField}
                        name="vat_number"
                        value={values.vat_number || ''}
                        onChange={handleChange}
                        title={t('pages.companyInfo.inputs.vat_number.label')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={t('pages.companyInfo.inputs.vat_number.placeholder')}
                        className={commonClasses.textInput}
                        optional
                      />
                    </Grid>

                    <Grid item>
                      <CustomDatePicker
                        id="vat_registration_date"
                        name="vat_registration_date"
                        label={t('pages.companyInfo.inputs.vat_registration_date.label')}
                        value={values.vat_registration_date}
                        onChange={(date: Moment | null) => {
                          setFieldValue('vat_registration_date', date?.toDate() ?? null);
                        }}
                        clearable
                        optional
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Form>
          </Layout>
        );
      }}
    </Formik>
  );
};

export default CompanyInfo;
